import React from 'react'
import './styles.css'
// import { makeStyles } from '@material-ui/core/styles';



const SpecificationItem = ({ k, v }) => {

  // const styles = useStyles()

  return <div class = {`product-specification-item`}>

  { k == "" ? null : <p class = "product-specification-key">{k}</p> }
  { v == "" ? null : <p class = "product-specification-val">{v}</p>}
  </div>

}


export default SpecificationItem