import React, { useEffect, useRef, useState, memo }  from 'react';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


const FeaturePanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role = "tabpanel"
      hidden = {value !== index}
      id = {`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Box p = {3}>
          <Typography>{children}</Typography>
        // </Box>
      )}
    </div>
  );
}


export default memo(FeaturePanel)