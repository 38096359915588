import React from 'react'
import Grid from '@material-ui/core/Grid';
import SpecificationItem from './specification-item'


// const xs = 12

// const sm = 6

// const md = 4

// const lg = 4

// const xl = 4

// const features = [
//   [ "Tailgating", "Yes"],
//   ["Cabinet Size", "100mm"],
//   ["Cabinet Size", "100mm"],
//   ["Cabinet Size", "100mm"],
//   ["Cabinet Size", "100mm"],
//   ["Cabinet Size", "100mm"],
//   ["Cabinet Size", "100mm"]
// ]


const Specification = ({ features }) => {

  const col = 3
  const row = 2

  const rows = []
  const cols = []

  console.log(features)


  return <div class = {`product-specification`}>

   
    { typeof features != 'undefined' ? Object.entries(features).map(f => {
        return <SpecificationItem k = {f[0]} v = {f[1]}></SpecificationItem>
      }) : null

    }

  
  </div>

}



export default Specification