import React, { useEffect, useRef, useState, memo }  from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// import Logo from '@src/asset/Logo.png';
import AppBar from '@material-ui/core/AppBar';
// import Button from '@material-ui/core/Button';
import Glide, { Anchor } from '@glidejs/glide'

// import SFZ from '@src/asset/sfz_remove.png'
import { Top, Footer, Meta, Breadcrumbs} from '@src/share-component'
import Specification from './product-component/specification'
import FeaturePanel from './product-component/feature-panel'
import { useIntl, FormattedMessage, injectIntl } from "gatsby-plugin-react-intl"
//import { createMuiTheme } from '@material-ui/core/styles';
// import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';

import './product.css'
import style from './product.css'
// import style from './product.css';
import '@src/styles/common.css'
import "@glidejs/glide/dist/css/glide.core.min.css"
// import { Helmet } from "react-helmet"
import Loading from '@src/share-component/loading/loading';
// const requireDir = require('require-dir');
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
// const features = ["slimmest flip gate", "high durability"]


const Product = ( data ) => {

  // console.log('[debug] styles => ', style)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'))

  const language = data.pageContext.intl.language
  const relativeDir = data.pageContext.rDir
  const imageUrls = data.pageContext.imageUrls
  const catalogLang = data.pageContext.catalogLang
  const videoUrls = data.pageContext.videoUrls
  const category = data.pageContext.category
  let video = null
  let videoContainer = null

  // console.log("product => ", catalogLang)
  // console.log("product => ", imageUrls)
  // console.log("product => ", category)
  // console.log("product => ", videoUrls)
  let glide = null
  const json = require(`@src/${relativeDir}/${language}.json`)
  // const json = require(`@src/${relativeDir}/${language}.json`)

  const [ value, setValue ] = useState(0);
  const handleChange = (event, value) => { if (value == 0) setValue(value) };
  const ref = useRef(null)
  const intl = useIntl()
  const slugs = [
    { url : `/${intl.locale}/`, name : intl.formatMessage({ id : "slug-home"})},
    { url : `/${intl.locale}/solutions/`, name : intl.formatMessage({ id : "slug-solutions"}) },
    { url : `/${intl.locale}/solutions/${category}/`, name : intl.formatMessage({ id : `slug-${category}`}) },
    { url : `/${intl.locale}/solutions/${category}/${json.id}`,  name : json.id }
  ]
  const sleep = (sec) =>{
    return new Promise(resolve => setTimeout(() => resolve(), sec));
  } 
  const scroll = () => { 

    window.scrollTo({
      top: ref.current.offsetTop - 50,
      behavior: "smooth"
  });  
    // ref.current.scrollIntoView({ behavior : 'smooth' })
     } 
  useEffect(() => {
    glide = new Glide('#product-glide', {
      type: 'carousel',
      perView: 1,
      focusAt: 'center',
      // autoplay : 2000,
      hoverpause : true
    }).mount()
    return glide.destroy()
  }, [])

  useEffect(() => {
    glide = new Glide('#product-glide', {
      type: 'carousel',
      perView: 1,
      focusAt: 'center',
      // autoplay : 2000,
      hoverpause : true
    }).mount()

    if (typeof videoUrls[0] != "undefined"){
      video = document.getElementById('product-video')
      videoContainer = document.getElementById('product-video-container')
      video.onended = function(e) {
        videoContainer.classList.add('product-video-hide')
      };
    }

    // glide.update()
  }, [isMobile])
  const getFeatureContainer = () => {

  }

  const onClickCloseVideo = () => {

    if (typeof videoUrls[0] != "undefined") 
      videoContainer.classList.add('product-video-hide')

  }

  const onEndVideo = () => {

    
  }

  const onClickVideo = () => {

    videoContainer.classList.remove('product-video-hide')
    video.play()
  }

  const GlideContainer = () => (
    <React.Fragment>
      <div class = 'product-glide-container'>
        <div id = 'product-glide' class = "glide">
          <div data-glide-el = "track" class = "glide__track">
            <ul class = "glide__slides">
              {
                json["product-slide-image"].map((image, idx) => (
                  <li class = "glide__slide product-glide">
                    <img alt = {`${intl.formatMessage({ id : `slug-${category}`})}-${json.id}\t${json.slogan}-${idx}`} src = {imageUrls[image]} class = {`product-glide-img`}/>
                    {/* <a></a> */}
                  </li>
                ))
              }
            </ul>
          </div>
          <div class="glide__bullets product-glide-bullets-container" data-glide-el="controls[nav]">
          {
                json["product-slide-image"].map((image, i) => (
                  <button class="product-glide-bullet" data-glide-dir={`=${i}`}></button>
                ))
              }
          
          </div>
        </div>
      </div>
    </React.Fragment>
  )
  
  return (
  <React.Fragment>
    <Meta
      title = {`${json.id}\t${json.slogan}`}
      keywords = {`${json["meta-keywords"]}`}
      description = {json["meta-description"]}
      slug = {`/solutions/${category}/${json.id.toLowerCase()}/`}
    />
    <Loading callback = {scroll}/>
    <div class = 'page'>
    <Top useColorBar = {true} slogan = {`${json.id}\t${json.slogan}`} />
     
    <div ref = {ref} class = 'product-crumb-container'><Breadcrumbs slugs = {slugs}/></div>
    { isMobile ? <GlideContainer/> : null }
    <div  class = 'product-container'> 
      <div class = {`product-feature-container`}>

        {/* <div class = {`product-category`}> {json.category} </div> */}
        <h2 class = {`product-name`}> {json.id} </h2>
        <p class = {`product-slogan`}> {json.slogan} </p>
        <p class = {`product-description`}> {json.description} </p>
      </div>
      { isMobile ? null : <GlideContainer/> }
      <div class = 'product-feature-container-2'>

      <AppBar position = "relative" color = "transparent" classes = {{ root : "product-feature-bar"}}>
        <Tabs classes = {{ root : "product-tabs" }}selectionFollowsFocus	= {false} scrollButtons = "off" variant = "standard" value = {value} onChange = { handleChange } aria-label = "simple tabs example">
          <Tab label = {intl.formatMessage({ id : "product-feature"})} />
            { typeof catalogLang[intl.locale] == "undefined" ? null : <Tab href = {catalogLang[intl.locale]} target="_blank" label = {intl.formatMessage({ id : "product-catalog"})} onClick = { (e) => e.preventDefault}/> }
            { typeof videoUrls[0] == "undefined" ? null : <Tab label = {intl.formatMessage({ id : "product-video"})} onClick = { (e) => onClickVideo() }/> }
            {/* { imageUrls.length == 1 ? null : <Tab label = {intl.formatMessage({ id : "product-cases"})} onClick = { (e) => onClickVideo() }/> } */}
        </Tabs>
      </AppBar>
      <FeaturePanel class = "product-tab" value = {value} index = {0}>
        <Specification features = {json.features}/>
      </FeaturePanel>
      {/* <FeaturePanel value = {value} index = {1}>
      <Button color = "primary">
        <Link href = {catalogUrls[0]} target="_blank" onClick = { (e) => e.preventDefault}>
        {json.id}
        </Link>
      </Button> */}
      
      {/* </FeaturePanel> */}
      {/* <FeaturePanel value = {value} index = {2}> */}
  {/* </FeaturePanel> */}
      </div>
    </div>
    { 
      typeof videoUrls[0] == "undefined" ? null : <div id = 'product-video-container' onClick = {onClickCloseVideo}class = 'product-video-container'>
        <video id = 'product-video' class = "product-video" autoPlay muted playsInline>
          <source src = {videoUrls[0]} type = "video/mp4" />
        </video>
      </div>
    }
    <Footer/>
    </div>
    </React.Fragment>
  )
}


const areEqual = (prevProps, nextProps) => {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */

  return true;
}

export default Product
// export default memo(Product, areEqual)